<template>
  <div class="ybmxm">
    <!-- 发起流转审批弹窗 -->

    <div class="project">
      <div style="padding: 0 30px">
        <h3>流程配置</h3>
      </div>
      <div class="table-card">
        <div class="table-con">
          <el-table border class="table" :data="tableData" style="width: 100%">
            <el-table-column
              align="center"
              prop="key"
              label="流程ID"
              width="200px"
            ></el-table-column>

            <el-table-column
              align="center"
              prop="processName"
              label="流程名称"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="version"
              label="版本"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="createTime"
              label="发布时间"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="updateTime"
              label="更新时间"
            ></el-table-column>
            <el-table-column align="center" label="操作" width="200">
              <template slot-scope="scope">
                <el-button
                  style="height: 28px; padding: 0 8px"
                  type="primary"
                  @click="noSelect(scope.row)"
                  >配置</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog
      v-if="dialogVisible"
      title="设置流程节点负责人"
      :visible.sync="dialogVisible"
      width="70%"
    >
      <div class="lcmc">
        <div>
          <span>流程名称:</span>{{ lcpz.processName ? lcpz.processName : "" }}
        </div>
        <div><span>流程ID:</span>{{ lcpz.key ? lcpz.key : "" }}</div>
      </div>
      <div class="pzlct">
        <div class="yuan">
          {{
            HistoryTask.workflowConfigItemList[0].nodeName
              ? HistoryTask.workflowConfigItemList[0].nodeName
              : ""
          }}
        </div>
        <div class="arrow-container">
          <div class="vertical-line"></div>
          <!-- 竖杠 -->
          <div class="arrow-down"></div>
          <!-- 向下的箭头 -->
        </div>

        <!-- 添加审批人 -->
        <div
          v-for="(item, index) in HistoryTask.workflowConfigItemList1"
          :key="index"
        >
          <div
            class="zheng"
            v-if="
              item.twActivityPeopleConfigVO.typeName || item.isRequired == '0'
            "
          >
            <span v-if="item.twActivityPeopleConfigVO">
              {{
                item.twActivityPeopleConfigVO.typeName
                  ? item.twActivityPeopleConfigVO.typeName
                  : item.nodeName
                  ? item.nodeName
                  : ""
              }}（{{
                item.twActivityPeopleConfigVO.assigneeName
                  ? item.twActivityPeopleConfigVO.assigneeName
                  : ""
              }}）
              <span style="color: #51a9dd; margin-left: 10px" @click="set(item)"
                >设置</span
              >
              <span
                style="color: #51a9dd; margin-left: 10px"
                v-if=" item.twActivityPeopleConfigVO.typeName && item.isRequired == '1'"
                @click="del(item)"
                >删除</span
              >
            </span>
          </div>
          <div class="zheng" v-else>
            <el-button class="but" icon="el-icon-plus" @click="add(item, index)"
              >添加审批人</el-button
            >
          </div>
          <div class="arrow-container">
            <div class="vertical-line">
              <!-- <buttton ></buttton> -->
            </div>
            <!-- 竖杠 -->
            <div class="arrow-down"></div>
            <!-- 向下的箭头 -->
          </div>
        </div>

        <div class="yuan">
          {{
            HistoryTask.workflowConfigItemList[1].nodeName
              ? HistoryTask.workflowConfigItemList[1].nodeName
              : ""
          }}
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clearSelection()">关闭</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogVisible1"
      width="70%"
      append-to-body
      title="配置"
      style="z-index: 9999999"
    >
      <div>
        <table class="tab">
          <tr>
            <td>流程节点名称</td>
            <td>办理人角色</td>
            <td>办理人</td>
          </tr>
          <tr>
            <td>审批节点</td>
            <td>
              <el-input
                style="width: 200px"
                v-model="form.name"
                autocomplete="off"
              ></el-input>
            </td>
            <td>
              <el-select
                v-model="form.index.realName"
                placeholder="请选择"
                @change="huazhong"
              >
                <el-option
                  v-for="(item, index) in Datalist"
                  :key="index"
                  :label="item.realName"
                  :value="index"
                >
                </el-option>
              </el-select>
            </td>
          </tr>
        </table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">关闭</el-button>
        <el-button type="primary" @click="assigneeConfig()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { selectFatherKey, selectProcess, assigneeConfig ,deleteAssigneeConfig} from "@/api/spgl";
import { selectRoleList } from "@/api/RuralFinance";
import { log } from "console";
export default {
  components: {},
  data() {
    return {
      form: {
        name: "",
        index: {},
        key: {},
      },
      tableData: [],
      HistoryTask: {},
      lcpz: {}, // 流程节点信息
      Datalist: [], // 角色列表
      dialogVisible: false,
      dialogVisible1: false,
    };
  },
  computed: mapGetters(["userInfo", "identityObj"]),
  created() {
    this.getlist();
    this.selectRoleList();
  },
  methods: {

    // 删除流程节点负责人
    async del(row) {
      console.log(row);
      let res = await deleteAssigneeConfig({ 
        id: row.twActivityPeopleConfigVO.id,
      });
      if (res.code == 0) {
        this.$message.success("删除成功");
        // 调用--获取配置信息列表
        this.getHistoryTask(this.lcpz);
      } else {
        this.$message.error("删除失败");
      }
    },
    huazhong(row) {
      this.form.index = this.Datalist[row];
      console.log("xz", row);
    },
    // 获取列表
    async getlist() {
      let res = await selectFatherKey();
      console.log("res", res);
      this.tableData = res;
    },

    //获取配置信息列表
    async getHistoryTask(e) {
      console.log(e);
      let res = await selectProcess({
        processId: e.key,
      });
      this.HistoryTask = res;
    },

    // 配置流程节点负责人
    async assigneeConfig(row) {
      console.log(this.form);
      let content = localStorage.getItem("userInfo");
      let deptId = JSON.parse(content).content.deptId;
      console.log("deptId", deptId);
      let params = {
        assignee: this.form.index.userId,
        assigneeType: this.form.index.roleId,
        deptId: deptId,
        key: this.form.key,
        typeName: this.form.name,
      };
      let res = await assigneeConfig(params);
      this.dialogVisible1 = false;

      // 调用--获取配置信息列表
      this.getHistoryTask(this.lcpz);
      console.log("res", res);
    },

    // 设置流程节点负责人
    set(row) {
      console.log(row);
      this.dialogVisible1 = true;
      this.form.key = row.nodeKey;
      this.form.name = row.twActivityPeopleConfigVO.typeName;
      this.form.index.realName = row.twActivityPeopleConfigVO.assigneeName;
      this.form.index.userId = row.twActivityPeopleConfigVO.assignee;
      this.form.index.roleId = row.twActivityPeopleConfigVO.userType;
    },
 
    noSelect(row) {
      this.dialogVisible = true;
      // 设置流程节点负责人
      this.lcpz = row;
      this.getHistoryTask(row);
    },

    // 添加审批人
    add(item, index) {
      console.log(item, index);
      this.HistoryTask.workflowConfigItemList1[index].isRequired = "0";
    },

    async selectRoleList() {
      let content = localStorage.getItem("userInfo");
      let deptId = JSON.parse(content).content.deptId;
      console.log("deptId", deptId);
      let params = {
        deptId: deptId,
        pnum: "1",
        psize: "100",
      };
      let res = await selectRoleList(params);
      this.Datalist = res.data;
    },

    clearSelection() {
      this.dialogVisible = false;
    },

    // handleRowClass({ row, rowIndex }) {
    //   // 行樣式
    //   if (rowIndex % 2 === 0) {
    //     return "odd";
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  border: 1px solid #000;
  border-collapse: collapse; /* 合并边框 */
  text-align: center;
  td {
    width: 30%;
    height: 50px;
    border: 1px solid #000;
    padding: 0; /* 取消内边距 */
    margin: 0; /* 取消外边距 */
  }
}
.arrow-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* 居中对齐 */
  margin: 20px; /* 添加一些外边距 */
}
.vertical-line {
  position: relative;
  width: 4px; /* 竖杠的宽度 */
  height: 40px; /* 竖杠的高度 */
  background-color: black; /* 竖杠颜色 */
  .but {
    position: absolute;
    width: 200px; /* 竖杠的宽度 */
    height: 40px; /* 竖杠的高度 */
    font-size: 20px;
    color: #00a0e9;
    left: 20px;
  }
}
.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent; /* 左边透明边框 */
  border-right: 10px solid transparent; /* 右边透明边框 */
  border-top: 10px solid black; /* 上边黑色边框 */
}
.pzlct {
  margin-top: 20px;
  display: flex;
  align-items: center; /* 垂直居中 */
  flex-direction: column; /* 垂直排列 */
  justify-content: space-between; /* 上下分布 */
  text-align: center;
}
.lcmc {
  display: flex;
  font-size: 18px;
  span {
    font-size: 20px;
  }
  div {
    margin-left: 50px;
  }
}
.yuan {
  width: 200px;
  height: 100px;
  border: 1px solid #000;
  border-radius: 50%;
  line-height: 100px;
}
.zheng {
  width: 250px;
  height: 100px;
  border: 1px solid #000;
  border-radius: 15px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex; /* 使用 flex 布局 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  text-align: center; /* 文字居中 */
}
.ybmxm {
  text-align: left;
  height: 100%;
  position: relative;
  // margin-top: 46px;

  .s-title {
    .title {
      margin-left: 10px;
      font-size: 15px;
      font-weight: 600;
    }
  }
  .project {
    // margin-top: 38px;
    .table-con {
      margin-top: 12px;
      padding: 0 15px 0 18px;
    }
  }
  .news {
    margin-top: 33px;
  }
  .pagenation {
    // margin-top: 0;
    // position: absolute;
    // right: 20px;
    // bottom: 33px;
    text-align: right;
  }
}
.query-form {
  display: flex;
  .el-form-item {
    display: flex;
    align-items: center;
    .el-form-item__content {
      width: 100px;
    }
  }
  .query-btn {
    width: 70px;
  }
}
</style>

<style lang="less">
.ybmxm {
  .el-dialog__wrapper {
    position: absolute;
  }
  .v-modal {
    position: absolute;
  }
  .el-checkbox-button__inner {
    width: 160px;
    border-left: 1px solid #dcdfe6;
  }
  .el-dialog--center .el-dialog__body {
    padding: 25px 60px 30px 38px;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    border-radius: 5px;
    border: solid 1px #00a0e9;
    background: #fff;
    color: #00a0e9;
  }
  .el-dialog__footer {
    padding: 10px 20px 45px;
  }
}
.disUoloadSty .el-upload--picture-card {
  display: none; /* 上传按钮隐藏 */
}
</style>
